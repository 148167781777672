import Modal from '../../utils/Modal'
import ModalBody from '../../utils/Modal/ModalBody'
import ModalHeader from '../../utils/Modal/ModalHeader'
import NewWorldOptionCard from '../../cards/NewWorldOptionCard'
import { useAppModalsContext } from '../../../context/AppModalsContext'
import PropTypes from 'prop-types'
import backgroundImage from '../../../assets/images/backgrounds/world-option-card-bg.png'
import { HiCubeTransparent, HiSquares2X2 } from 'react-icons/hi2'
import { FiUpload } from 'react-icons/fi'
import styles from './NewWorldModal.module.css'
import CardImage from '../../cards/NewWorldOptionCard/CardImage'
import ModalCircularProgressHeader from '../../related/ModalCircularProgressHeader'
import { MODALS_NAMES } from '../../../utils/constants/app.constants'
import TemplatesModal from '../TemplatesModal/TemplatesModal'
import { useState } from 'react'

const NewWorldModal = ({ open, onClose }) => {
  const { setCurrentStep, handleModalToggle } = useAppModalsContext()
  const [show,setShow] = useState(false)

  const handleSelectTemplate = () => {
    onClose()
    console.log("clicked"+MODALS_NAMES.TEMPLATES)
    handleModalToggle(MODALS_NAMES.TEMPLATES)
  }

  const handleCreateFromScratch = () => {
    setCurrentStep((prev) => prev + 1)
    onClose()
    handleModalToggle(MODALS_NAMES.CHOOSE_AVATAR)
  }

  const handleImportWorld = () => {
    onClose()
    handleModalToggle(MODALS_NAMES.IMPORT_WORLD)
  }

  const showTemp= () => {
    console.log('Open Template Modal')
    setShow(true)
  }

  return (
    <>
    {show ? (
    <TemplatesModal
              open={true}
              //onClose={() => handleModalToggle(MODALS_NAMES.TEMPLATES)}
                onClose={() => onClose()}
            />
    ):
      <Modal open={open} onClose={onClose} size={'xxxl'}>
        <ModalHeader
          open={open}
          onClose={onClose}
          title={
            <ModalCircularProgressHeader
              title={'Choose how to create new world'}
            />
          }
        />
        <ModalBody>
          <div className='flex gap-4'>
            <NewWorldOptionCard
              actionText='Select template'
              image={
                <CardImage
                  icon={<HiSquares2X2 className={styles.icon} />}
                  image={backgroundImage}
                />
              }
              onClick={handleSelectTemplate}
            />

            <button onClick={showTemp}>Test</button>

            

            <NewWorldOptionCard
              actionText='Create from scratch'
              image={
                <CardImage
                  icon={<HiCubeTransparent className={styles.icon} />}
                  image={backgroundImage}
                />
              }
              onClick={handleCreateFromScratch}
            />

            <NewWorldOptionCard
              actionText='Import World'
              image={
                <CardImage
                  icon={<FiUpload className={styles.icon} />}
                  image={backgroundImage}
                />
              }
              onClick={handleImportWorld}
            />
          </div>
        </ModalBody>
      </Modal>
}
    </>
  )
}

NewWorldModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NewWorldModal
